<template>
  <div id="conorderbox">
    <div class="hadheight"></div>
    <Header />
        <div class="conorderbox-sec1">
            <div class="basecont">
                <div class="con_nav">
                    <router-link to="/my/myorder" class="returngt">
                        <i><img src="../../assets/images/returniconig1.png" alt=""></i>
                        <span>返回订单</span>
                    </router-link>
                </div>
                <div class="cont">
                    <div class="logo">
                        <router-link to="/">
                            <img src="../../assets/images/gouwuliuimg1.png" alt="">
                        </router-link>
                    </div>
                    <div class="f_font">
                        <div class="item on">
                            <i><img src="../../assets/images/gouwuicnimgf1.png" alt="加入购物车"></i>
                            <div class="tit">加入购物车</div>
                        </div>
                        <div class="item on">
                            <i><img src="../../assets/images/gouwuicnimgf2.png" alt="确认订单"></i>
                            <div class="tit">确认订单</div>
                        </div>
                        <div class="item on">
                            <i><img src="../../assets/images/gouwuicnimgf3.png" alt="付款成功"></i>
                            <div class="tit">付款成功</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="prynet-sec1 conorderbox-sec2 base150">
            <div class="basecont">
                <div class="cont">
                    <div class="lt">
                        <div class="prynet_cont">
                            <div class="prynet_tit">
                                <div class="tit basefont30">订单提交成功，请您尽快完成支付！</div>
                                <div class="text">请您在<span>72小时</span>内完成付款，过时系统将自动取消订单。</div>
                            </div>
                            <div class="zhifu">
                                请选择支付方式
                            </div>
                            <div class="name">第三方支付</div>
                            <div class="f_jut">
                                <a href="javascript:;" @click="zhif('alipayPayPC',0)" :class="{li:true,on:content==0}">
                                    <img src="../../assets/images/zhifuicon1.jpg" alt="">
                                </a>
                                <a href="javascript:;" @click="zhif('wechatPayPC',1)" :class="{li:true,on:content==1}">
                                    <img src="../../assets/images/zhifuicon2.jpg" alt="">
                                </a>
                                <!-- <a href="javascript:;" @click="zhif(2)" :class="{li:true,on:content==2}">
                                    <img src="../../assets/images/zhifuicon3.jpg" alt="">
                                </a> -->
                            </div>
                        </div>
                    </div>
                    <div class="gt">
                        <div class="f_nei">
                            <div class="tit">支付信息</div>
                            <div class="ding">
                                <!-- <div class="li">
                                    <div class="name">订单号</div>
                                    <div class="text">{{myOrderParams.orderNo}}</div>
                                </div> -->
                                <div class="li">
                                    <div class="name">收货地址</div>
                                    <div class="text">{{myOrderParams.province}}{{myOrderParams.city}}******</div>
                                </div>
                                <div class="li">
                                    <div class="name">商品信息</div>
                                    <template
                                        v-for="(item,index) in myOrderParams.orderItemInfoList">
                                        <div class="text list" :key="index">
                                            {{item.skuName}}
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="zong">
                                <span>总计</span>
                                <div class="jia">¥ {{myOrderParams.actualPrice}}</div>
                            </div>
                            <a href="javascript:;" @click="subCon()" class="hm-but f_but">
                                <span>确认支付</span>
                                <i>
                                    <img src="../../assets/images/jiantouyo1.png" >
                                    <img src="../../assets/images/jiantouyo2.png" >
                                </i>
                            </a>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        
        <transition name="fade">
            <div class="payalt" v-show="alt">
                <div class="bag" @click="altHide"></div>
                <div class="f_cont">
                    <div class="close" @click="altHide">
                        <img src="../../assets/images/closeiconimg1.png" alt="">
                    </div>
                    <div class="f_tit basefont24">
                        扫码快速支付
                    </div>
                    <!-- <div class="f_img">
                        <img :src="payimg" alt="">
                    </div> -->
                    <iframe :src="payimg" frameborder="0" class="f_img2" v-show="content==0"></iframe>
                    <div ref="qrcode" id="qrcode" class="f_img3" v-show="content==1"></div>

                </div>
            </div>
        </transition>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapState } from 'vuex';
import QRCode from 'qrcodejs2';
export default {
  name: "Payment",
  components: {
    Header,
    Footer
  },
  data(){
    return {
        content: -1,
        alt: false,
        payimg: '',
        myOrderParams: JSON.parse(window.localStorage.getItem("orderMessage"))
    }
  },
  created(){
    /* 获取订单信息 */
    this.getOrderMsg();
    
  },
  mounted() {
       
  },
  computed:{
    ...mapState({
        login: state => state.loginStore.login,
    })
  },
  methods: {
    getOrderMsg(){ //获取订单信息
        this.$axios.post("/api2/portal/order/detail/getOrderNo",{
            orderNo: this.myOrderParams.orderNo
        }).then(res => {
            if(res.code=="000000"){
                this.myOrderParams = res.data;
            }else{
                this.$message({
                    message: res.message,
                    type: 'error',
                });
            }
        }).catch(error => {
            console.log(error);
        });
    },
    returncli(){  //返回上一页
        this.$router.back();
         
    },
    
    async subCon(){  //成功后跳转
        // this.$router.push({path: "Complete"});
         let res = await this.$axios.post("/api2/portal/order/detail/getOrderNo",{
            orderNo: this.myOrderParams.orderNo
        });
       if(res.code){
         this.myOrderParams = res.data;
         this.$nextTick(() => {
            if(this.myOrderParams.orderStatus==2){
                this.$message({
                    message: "支付成功！",
                    type: 'success',
                }); 
                this.$router.push("/my/myorder");
            }else{
                this.$message({
                    message: "请付款后在确认支付！",
                    type: 'error',
                }); 
            }
         });
        
       }else{
            this.$message({
                message: res.message,
                type: 'error',
            });
       }
       
    },
    zhif(payType,index) {  //订单支付弹窗显示
        this.content = index;
        let data = {
            outTradeNo: this.myOrderParams.orderNo,
            description: this.myOrderParams.orderItemInfoList[0].skuDesc,
            payFee: this.myOrderParams.actualPrice,
            payType: payType,
            title: this.myOrderParams.orderItemInfoList[0].skuName,
            accountNo: this.login.id 
        };
        this.$axios.post('/api2/portal/auth/order/detail/payForPC',data).then(res => {
            // console.log(res);
            if(res.code=="000000"){
                this.content = index;
                // window.open(res.data.codeUrl);
                
                if(index==0){
                    this.payimg = res.data.codeUrl;
                     this.alt = true;
                }else{
                    this.$refs.qrcode.innerHTML = "";
                    new QRCode(this.$refs.qrcode, {
                        text: res.data.codeUrl, //要生成二维码的网址
                        width: 256,  //图像宽度
                        height: 256, //图像高度
                        colorDark: '#000000',  //前景色
                        colorLight: '#ffffff', //背景色
                        margin:0, //外边距
                        correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
                    });
                    if(window.innerWidth<1199){
                        // window.open(res.data.codeUrl);
                    }else{
                         
                    }
                    this.alt = true;
                }
               
            }else{
                this.$message({
                    message: res.message,
                    type: "error"
                });
            }
        }).catch(error => {
            console.log(error);
        });
        // 弹窗显示
        // this.alt = true;
    },
    altHide() { //弹窗隐藏
        this.alt = false;
    }
  }
}
</script>

<style scoped>
   .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
